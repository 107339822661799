.mobile_sticky_footer {
  padding-top: 4rem;
  & > div {
    bottom: 0;
    z-index: 100;
  }
  & .phone {
    border-left: 1px solid $purpleTransparent;
    z-index: 100;
  }
}

.menu-footer {
  // We do not use $grey because it is too dark on purple background
  --grey: #999;

  @media screen and (max-width: $mobileBreakPoint) {
    > div > div:not(:first-child) {
      .menu-footer__section {
        border-top: 1px solid #48405c;
      }
    }
  }

  .menu-footer__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;

    &.menu-footer__section--mobile {
      cursor: pointer;
      padding: 3rem 30px;
      margin-left: -30px;
      margin-right: -30px;

      &::after {
        content: '';
        display: block;
        height: var(--height);
        width: var(--width);
        background-color: $white;
        margin-left: 1rem;
        flex-shrink: 0;
        --height: 7px;
        --width: 11px;
        -webkit-mask-image: url('../images/collapse.svg');
        mask-image: url('../images/collapse.svg');
        transition: transform 0.15s ease-out;
        transform: rotate(0deg);
      }

      &[aria-expanded='true']::after {
        transform: rotate(-180deg);
      }
    }

    &.menu-footer__section--desktop {
      display: none;
    }
  }

  .menu-footer__list {
    margin-bottom: 1rem;
    a {
      font-size: 11pt;
      color: var(--grey);
      font-weight: $fontWeightBook;
      margin: 8px 0;
    }
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .menu-footer {
    margin-bottom: 3rem;

    .menu-footer__section {
      &.menu-footer__section--mobile {
        display: none;
      }
      &.menu-footer__section--desktop {
        display: flex;
        color: var(--grey);
      }
    }

    .menu-footer__list {
      display: flex;
      padding-top: 0.5rem;

      a {
        color: $white;
      }

      .row .col {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
