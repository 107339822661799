.bgContentImage img {
  display: block;
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}

@media screen and (min-width: $desktopBreakPoint) {
  .bgContentImage {
    position: relative;

    img {
      position: absolute;
      height: 650px;
      width: auto;
      max-width: 100%;
      max-height: none;
      z-index: -1;
    }
  }

  .bgContentImage-right img {
    right: 0;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  // can do better, this is not the best way to do it
  .needGreenArrow {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-image: url('../images/arrows/big_green_arrow.png');
    top: 150px;
    height: 100vh;
    z-index: -1;
    background-size: 80%;
    background-repeat: no-repeat;
    & > img {
      position: absolute;
      width: 60%;
      right: -100px;
      &.loaded {
        top: -150px;
      }
    }
  }
  .desktopExclusifPaddingForNeedPage {
    padding-top: 4rem;
  }

  .illustration {
    position: relative;
    & > img {
      right: 0;
      position: absolute;
      z-index: -1;
      max-width: 50%;
    }
  }

  .startdesktopcentermobile {
    justify-content: flex-start;
    align-items: center;
  }
  .enddesktopcentermobile {
    justify-content: flex-end;
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  // can do better, this is not the best way to do it
  .needGreenArrow {
    position: relative;
    overflow: hidden;
    height: 475px;
    & > img {
      width: 100%;
      transform: scale(1.9);
      background-image: url('../images/arrows/big_green_arrow.png');
      background-size: 100%;
      background-position-x: -200px;
      background-position-y: 130px;
      background-repeat: no-repeat;
    }
  }

  .illustration {
    position: relative;
    & > img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      z-index: -1;
    }
  }
  .startdesktopcentermobile,
  .enddesktopcentermobile {
    justify-content: center;
  }
}
