.modal-body {
  overflow-y: auto;
}

.modal-content {
  border: 0;
  border-radius: 20px;
}

@media (min-width: $desktopBreakPoint) {
  .modal-lg {
    max-width: 870px;
  }
}

@media (max-width: $desktopBreakPoint) {
  .modal.show {
    width: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    overflow-x: hidden;
    transition: 0.5s;
  }
  .modal-lg {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    margin: 0;
  }
}
