@import 'swiper/scss';

.swiper-full-width {
  overflow: visible;

  .swiper-slide {
    width: 270px;
  }
}

.needs-slider-header {
  display: flex;
  flex-wrap: wrap;

  > p {
    margin-right: 20px;
    margin-top: 5px;
  }

  button {
    background: none;
    border: 1px dashed $purple;
    border-radius: 50px;
    padding: 5px 20px;

    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 15px;
      color: $purple;
      line-height: 1.2;
    }
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .needs-slider-header {
    > p {
      margin-right: 30px;
    }
    button {
      padding: 8px 35px;

      &:hover {
        background: $purpleTransparent;
      }
    }
  }
}
