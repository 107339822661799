.filters-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;
  padding: 85px 30px 30px;
  background-color: $darkPurple;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .close-sticky {
    position: fixed;
    z-index: 10;
    top: 30px;
    right: 30px;
  }

  .illustration {
    display: none;
  }
}

.filters-overlay-content {
  height: 100%;
  transition: all 0.25s ease;
  transform: translateY(250px);
  visibility: hidden;
  opacity: 0;

  .show & {
    transition-delay: 0.5s;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  .filtersList {
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter {
      flex: 1;
    }

    button,
    a {
      display: inline-flex;
      align-items: center;
      height: 100%;
      border: 0 none;
      background-color: transparent;
      transition: color 0.1s ease;
      font-size: 14pt;
      font-weight: $fontWeightBold;
      line-height: 1.2;
      text-align: left;
      color: #3b3e45;
      text-decoration: none;

      &:hover,
      &.selected {
        color: $white;
      }
    }
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .filters-overlay {
    padding: 100px 30px 100px 15%;

    .illustration {
      display: block;
      position: absolute;
      right: 0;
      top: 50px;
      max-width: 100vw;
    }
  }

  .filters-overlay-content {
    .filtersList {
      width: calc(100vw - 150px);
      button,
      a {
        font-size: 30pt;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .filters-overlay {
    .illustration {
      top: 100px;
      right: 10%;
    }
  }
}
