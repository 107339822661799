body {
  font-family: CircularStd, normal;
  font-size: $text_size_mobile;
  font-weight: $fontWeightLight;
  line-height: 1.8;
  color: $purple;
  background-color: $white;
  position: relative;
}

h1 {
  color: $purple;
  font-size: $large_mobile;
  font-weight: $fontWeightBold;
}

h2 {
  color: $purple;
  font-size: $large_mobile;
  font-weight: $fontWeightBook;
}

blockquote {
  color: $purple;
  font-size: $large_mobile;
  font-weight: $fontWeightBook;
}

img {
  // Allows to img with size properties to keep their ratio
  height: auto;
}

// Do not display mouse focus, only keyboard focus
*:focus-visible {
  outline-color: invert !important;
  outline-style: solid !important;
  outline-width: 1px !important;
}
*:focus:not(:focus-visible) {
  outline: none !important;
}

// focus-visible trick do not work on input, because outline is defacto visible!
// our labels style is cristal clear, so we can remove the outline on input
textarea:focus-visible,
input:focus-visible {
  outline: none !important;
}

@media screen and (min-width: $mobileBreakPoint) {
  body {
    font-size: $text_size;
  }

  h1 {
    font-size: $large;
  }
  h2 {
    font-size: $large_mobile;
  }
  blockquote {
    font-size: $large;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.noStyle {
    font-size: 1rem;
    font-weight: $fontWeightLight;
    margin: 0;
    padding: 0;
  }
}
