.style-guide-container {
  background-color: #2e1d5917;
  border: 6px solid $purple;
  border-radius: 6px;
  padding: 10px 15px;
}

.style-guide-color-box {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  margin-bottom: 15px;
}
