@use 'mixins';
@use 'sass:math';

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $white;
  overflow-x: hidden;
  transition: 0.5s;
  &.opened {
    transform: translate3d(0, 0, 0);
    visibility: inherit;
    opacity: $fullOpacity;
  }
  &.closed {
    transform: translate3d(100px, 0, 0);
    visibility: hidden;
    opacity: $noOpacity;
  }
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  margin-top: 30px;
  transition: 0.5s;
  &.opening {
    transition-delay: 0.7s;
    transform: translate3d(0, 0, 0);
    visibility: inherit;
    opacity: $fullOpacity;
  }
  &.closing {
    transform: translate3d(100px, 0, 0);
    visibility: hidden;
    opacity: $noOpacity;
  }
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 60px;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.nav-desktop {
  height: 110px;

  &.header_shadow {
    box-shadow: 0px 5px 50px rgba(46, 29, 89, 0.1);
  }

  & > .row:first-child {
    height: 100%;
    flex-wrap: nowrap;
  }

  .menuContainer {
    max-width: 800px;

    & > * {
      flex-grow: 1;
      justify-content: center;
    }

    // First level item with subMenu
    & > div span {
      text-align: center;
    }

    // Menu items
    a,
    div {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      height: 100%;

      font-size: 13pt;
      color: $white;
      line-height: 1.2;
      font-weight: $fontWeightBook;
      text-decoration: none;
      cursor: pointer;

      svg path {
        fill: $white;
      }
    }
  }

  // Menu items variant white bg
  &.b-white .menuContainer {
    a,
    div {
      color: $purple;
      svg path {
        fill: $purple;
      }
    }
  }

  .linksContainer {
    & > *:nth-child(n + 2) {
      margin-left: 10px;
    }
  }

  // Override weird reset style
  a:not([href]):not([tabindex]) {
    color: $white;
  }
}

.headerMobile {
  background-color: $white;
  height: $mobileHeaderHeight;

  .header {
    height: 100%;
    padding: 0 30px;

    // Go purple if button is toggled!
    button.toggled {
      @include mixins.button {
        padding: 0;
      }

      color: $white;
      background: $purple;

      .line {
        background: $white;
      }
    }
  }

  nav {
    position: fixed;
    z-index: 1000;
    top: $mobileHeaderHeight;
    left: 0;
    bottom: 0;
    right: 0;
    background: $white;

    &.opened {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s ease;
      overflow-y: auto;

      & > div {
        overflow-x: hidden;
        position: relative;
        top: 25%;
      }
    }
    &.closed {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.25s, opacity 0.25s ease;

      // Get the nav out of the DOM!
      & > div {
        overflow: hidden;
        height: 0;
        transition: height 0s 0.4s; // .4 to be sure to not overflow
      }
    }
  }

  // Override weird reset style
  a:not([href]):not([tabindex]) {
    color: $white;
  }
}

// NEW !!

// Please report any new value to assets/js/ui/menu.js (var mobileBreakPoint)
$menu-breakpoint: 1360px;

/*
 * Animations only
 */

#navbar {
  .main-menu {
    display: none;
    transition: opacity 0.15s ease-out;

    &.is-leaving {
      display: flex !important; // disabled from html
      opacity: 0;
    }

    &.is-entering {
      display: flex !important; // disabled from html
      opacity: 0;
    }
    &.is-entered {
      display: flex !important; // disabled from html
      opacity: 1;
    }
  }

  // We do not use mobile first here, because mobile and desktop are very different
  @media screen and (max-width: $menu-breakpoint) {
    .sub-menu__opener {
      & + .sub-menu {
        display: none;

        .sub-menu__content {
          transition: opacity 0.15s ease-in-out;
          opacity: 0;

          > * {
            transition: transform 0.15s ease-in-out;
            transform: translateX(10px);
          }
        }
      }

      &.is-entering {
        & + .sub-menu {
          display: flex;
        }
      }

      &.is-entered + .sub-menu {
        display: flex;

        .sub-menu__content {
          opacity: 1;

          > * {
            transform: translateX(0px);
          }
        }
      }

      &.is-leaving + .sub-menu {
        display: flex;
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: calc($menu-breakpoint + 1px)) {
    .sub-menu__opener {
      & + .sub-menu {
        display: none;

        .arrow {
          transition: transform 0.05s ease-in-out 0.1s;
          transform: scaleY(0) rotate(45deg) !important;
        }

        .sub-menu__content {
          transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
          opacity: 0;
          transform: translateX(10px);
          box-shadow: 0px 1px 8px 3px rgba(46, 29, 89, 0.1);
        }
      }

      &.is-entering {
        & + .sub-menu {
          display: flex;
        }
      }

      &.is-entered + .sub-menu {
        display: flex;

        .arrow {
          transform: scaleY(1) rotate(45deg) !important;
        }

        .sub-menu__content {
          opacity: 1;
          transform: translateX(0px);
        }
      }

      &.is-leaving + .sub-menu {
        display: flex;

        .sub-menu__content {
          opacity: 0;

          transition: opacity 0.15s ease-in-out 0.1s;
          transform: translateX(0px);
        }

        .arrow {
          transition: transform 0.05s ease-in-out;
          transform: scaleY(0) rotate(45deg) !important;
        }
      }
    }
  }
}

/*
 * Navbar
 */

@mixin navbar-button {
  --height: 7px;
  --width: 11px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--menu-item-height);
  padding: 0 30px;
  width: 100%;

  color: $purple;
  border: 0;

  font-size: inherit;
  font-weight: $fontWeightBook;
  text-decoration: none;
  text-align: left;
  line-height: 1.2;
}

#navbar {
  // >> Reset common styles
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  // <<

  --navbar-height: 80px;
  --menu-item-height: 60px;

  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 0 30px;
  height: var(--navbar-height);
  z-index: 101;
  background-color: $white;

  .menu {
    a,
    button,
    .cat-name {
      @include navbar-button;
      background-color: transparent;
    }

    .cat-name {
      font-weight: bold;
      color: #6d618b;
      pointer-events: none;
    }
  }
}

// We do not use mobile first here, because mobile and desktop are very different
@media screen and (max-width: $menu-breakpoint) {
  // prevent scroll on mobile when menu is opened
  html:has(.main-menu.is-entered) {
    overflow: hidden;
  }

  #navbar {
    ul {
      li {
        border-top: 1px solid $lightGrey;
      }
    }

    button.sub-menu__opener {
      &::after {
        @include mixins.icon-collapse;
        transform: rotate(270deg);
        margin-right: 20px;
      }
    }

    button.sub-menu__closer {
      background: none;
      border: none;

      &::before {
        @include mixins.icon-collapse;
        transform: rotate(90deg);
        margin-right: 10px;
      }
    }

    .cta-list {
      button,
      a {
        @include navbar-button;
        color: $white;
        border-radius: 0;
        border-top: 0;

        justify-content: flex-start;
        gap: 1rem;

        &::before {
          @include mixins.icon-arrow;
          background-color: $white;
        }
      }
      :first-child {
        background-color: $purple;
      }
    }

    .menu {
      .menu-main-header,
      .sub-menu__closer {
        display: flex;
        align-items: center;
        height: var(--navbar-height);
        margin: 0;
        padding: 0 30px;
        border-top: 0 !important;
        color: $purple;
        font-weight: bold;
        width: auto;
      }
    }

    .main-menu {
      position: fixed;
      inset: 0;
      display: none;
      flex-direction: column;
      overflow-y: scroll;
      background: #fff;
      z-index: 1000;

      .sub-menu {
        position: fixed;
        inset: 0 !important; // do not take desktop submenu position into account
        z-index: 1001;
        overflow-y: scroll;

        .sub-menu__content {
          background: #fff;
          width: 100%;

          .footer {
            background-color: $purple;
            color: $white;
            padding: 0 30px;

            justify-content: flex-start;
            gap: 1rem;

            &::before {
              @include mixins.icon-arrow;
              background-color: $white;
            }
          }
        }

        li {
          padding-left: 20px;
          background-color: $white;
        }
      }
    }

    .main-menu__hamburger {
      // Do not hide overflowing shadow
      position: relative;
      display: flex;
      align-items: center;
      z-index: 1002;
    }
  }
}

@media screen and (min-width: calc($menu-breakpoint + 1px)) {
  $navbar-sticky-top: 20px;

  #global {
    margin-top: $navbar-sticky-top * 2;
  }

  #navbar {
    --navbar-height: 76px;
    --menu-item-height: 76px;

    position: sticky;
    top: $navbar-sticky-top;
    margin: 0 60px;
    padding: 0 15px;
    border-radius: calc((var(--navbar-height) / 2));

    border: 1px solid rgba(46, 29, 89, 0.1);
    box-shadow: 0px 1px 8px 0px rgba(46, 29, 89, 0.1);

    .main-menu__hamburger {
      display: none;
    }

    .main-menu,
    .cta-list {
      a,
      button {
        box-shadow: none;
      }
    }

    .cta-list {
      margin-left: auto; // align to end
      display: flex;
      flex-wrap: nowrap;
      flex-shrink: 0;
      gap: 10px;

      .secondary {
        border: 1px solid $purple;
      }
    }

    .main-menu {
      display: flex !important; // always visible on desktop, important to override style property
      flex-direction: row;
      align-items: center;
      justify-content: left;
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: visible; // cancel mobile scroll
      gap: 15px;

      .sub-menu__closer {
        display: none;
      }

      .menu {
        > ul {
          display: flex;
          flex-direction: row;
        }

        li {
          border-top: 0 none;
          padding: 0;
        }

        a,
        button {
          padding: 0 15px;
        }
      }

      .menu-main-header {
        display: none;
      }

      .sub-menu {
        --menu-item-height: 36px;

        width: max-content;
        position: absolute;
        z-index: 1;

        .sub-menu__content {
          background-color: $purple;
          border-radius: 20px;
          border: 1px solid $purple;
          overflow: hidden;
        }

        .cat-name {
          color: #c0bbcd;
          padding: 0;
          margin-bottom: 15px;
        }

        a {
          color: $white;
          padding: 0;
          justify-content: flex-start;

          &::before {
            @include mixins.icon-collapse;
            background-color: $white;
            transform: rotate(270deg);
            margin-right: 15px;

            transition: transform 0.15s ease-in;
          }

          &:hover {
            &::before {
              transform: translateX(5px) rotate(270deg);
            }
          }
        }

        $padding-content: 30px 30px;

        &.sub-menu__categories .sub-menu__content {
          .footer {
            background-color: $white;
            color: $purple;
            padding: 25px 30px;

            &::before {
              @include mixins.icon-arrow;
              margin-right: 15px;
              transform: rotate(0deg);
            }

            &:hover {
              &::before {
                transform: translateX(5px) rotate(0deg);
              }
            }
          }

          & > ul {
            display: flex;
            flex-direction: row;

            > li {
              padding: $padding-content;

              &:not(:last-child) {
                border-right: 1px dashed $white;
              }
            }
          }
        }

        &.sub-menu__items .sub-menu__content > ul {
          display: flex;
          flex-direction: column;

          padding: $padding-content;
        }

        // Arrow
        .arrow {
          position: absolute;
          width: 10px;
          height: 10px;
          z-index: -1;
          pointer-events: none;
          background-color: $purple;
          transform: rotate(45deg);
        }
      }
    }
  }
}

// @media screen and (min-width: 1300px) {
//   #global {
//     margin-top: 30px;
//   }
// }
