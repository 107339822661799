.card {
  & .card-header {
    & span.opened {
      -webkit-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      opacity: $fullOpacity;
      transform: rotate(0deg);
      visibility: inherit;
      right: 0.1rem;
      position: absolute;
    }
    & span.closed {
      -webkit-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      background-color: transparent;
      opacity: $noOpacity;
      visibility: hidden;
    }
    & .collapsed {
      & * {
        color: $grey;
      }
      & span.closed {
        -webkit-transition: all 0.3s linear 0s;
        transition: all 0.3s linear 0s;
        background-color: transparent;
        opacity: $fullOpacity;
        visibility: inherit;
      }
      & span.opened {
        -webkit-transition: all 0.3s linear 0s;
        transition: all 0.3s linear 0s;
        opacity: $noOpacity;
        transform: rotate(90deg);
        visibility: hidden;
        right: 0.1rem;
        position: absolute;
      }
    }
  }
}

.o-low {
  -webkit-transition: opacity 0.2s linear 0s;
  transition: opacity 0.2s linear 0s;
  opacity: 0.3;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*
 * Generic collapsible show/hide animation
 *
 * Allow to show/display with an animation
 */

[data-toggle='collapse'] {
  & .isCollapsed {
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  & .isNotCollapsed {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    transform: rotate(0deg);
  }

  &.collapsed {
    & .isCollapsed {
      opacity: 1;
    }
    & .isNotCollapsed {
      opacity: 0;
      transform: rotate(90deg);
    }
  }
}
