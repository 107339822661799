/*
 * Do NOT remove desktop overrided classes! They are all duplicated but it is wanted.
 * if you have, for exemple, `.mainText.default.light` on a desktop screen, .default will override .light.
 * So it's wanted to redeclare color and line-height classes
 */

.fontWeightM {
  font-weight: $fontWeightBook;
}
.fontWeightB {
  font-weight: $fontWeightBold;
}

.onelineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainText {
  &.default {
    color: $purple;
    font-size: $text_size_mobile;
    line-height: $text_line_height_mobile;
  }

  &.textHeightS {
    line-height: $text_line_height_small;
  }
  &.textHeightL {
    line-height: $text_line_height_large;
  }

  &.dark {
    color: $purple;
  }
  &.light {
    color: $grey;
  }
  &.white {
    color: $white;
  }
  &.lime {
    color: $lime;
  }
  &.red {
    color: $pink;
  }

  &.jumbo {
    font-size: $jumbo_mobile;
  }
  &.xlarge {
    font-size: $xlarge_mobile;
  }
  &.large {
    font-size: $large_mobile;
  }
  &.medium {
    font-size: $medium_mobile;
  }
  &.small {
    font-size: $small_mobile;
  }
  &.xsmall {
    font-size: $xsmall_mobile;
  }
  &.xxsmall {
    font-size: $xxsmall_mobile;
  }

  &.rarelyused {
    font-size: $rarelyused_mobile;
  }
  &.homepagetitle {
    font-size: $homepage_title_mobile;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .mainText {
    &.default {
      color: $purple;
      font-size: $text_size;
      line-height: $text_line_height;
    }

    &.textHeightS {
      line-height: $text_line_height_small;
    }
    &.textHeightL {
      line-height: $text_line_height_large;
    }

    &.dark {
      color: $purple;
    }
    &.light {
      color: $grey;
    }
    &.white {
      color: $white;
    }
    &.lime {
      color: $lime;
    }
    &.red {
      color: $pink;
    }

    &.jumbo {
      font-size: $jumbo;
    }
    &.xlarge {
      font-size: $xlarge;
    }
    &.large {
      font-size: $large;
    }
    &.medium {
      font-size: $medium;
    }
    &.small {
      font-size: $small;
    }
    &.xsmall {
      font-size: $xsmall;
    }
    &.xxsmall {
      font-size: $xxsmall;
    }

    &.rarelyused {
      font-size: $rarelyused;
    }
    &.homepagetitle {
      font-size: $homepage_title_desktop;
    }
  }
}
