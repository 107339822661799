.my-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.5;
  margin-bottom: 0; 

  input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    -border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-top: -4px; // align checkbox with label
    min-height: 20px;
    border-radius: 6px;
    min-width: 20px;
    background: #fff;
    border: 2px solid $purpleTransparent;

    &:focus {
      outline: 0;
    }

    &:checked {
      background: $purple;
      position: relative;

      &:before {
        content: "";
        display: block;
        color: $white;
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:focus {
        outline: 0;
      }
    }
  }
}
