.p-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 101;
}

@media screen and (min-width: $mobileBreakPoint) {
  .faq-side-menu-position {
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    z-index: 100;
  }
}
