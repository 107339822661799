@import 'conf';

@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  padding: 0 20px;

  border-radius: 25px;
  border: 2px solid transparent;

  font-size: $xsmall;
  font-weight: $fontWeightBold;
  appearance: none;
  user-select: none;

  @content;
}

@mixin icon-collapse {
  --height: 7px;
  --width: 11px;

  content: '';
  display: block;
  height: var(--height);
  width: var(--width);
  background-color: $purple;
  flex-shrink: 0;
  -webkit-mask-image: url('../images/collapse.svg');
  mask-image: url('../images/collapse.svg');
  mask-size: cover;
}

@mixin icon-arrow {
  --height: 14px;
  --width: 11px;

  content: '';
  display: block;
  height: var(--height);
  width: var(--width);
  background-color: $purple;
  flex-shrink: 0;
  -webkit-mask-image: url('../images/arrow.svg');
  mask-image: url('../images/arrow.svg');
  mask-size: cover;
}

@mixin icon-long-arrow {
  --height: 7px;
  --width: 50px;

  content: '';
  display: block;
  height: var(--height);
  width: var(--width);
  background-color: $purple;
  flex-shrink: 0;
  -webkit-mask-image: url('../images/list_arrow.png');
  mask-image: url('../images/list_arrow.png');
  mask-size: cover;
}
