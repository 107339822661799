.fat-arrow {
  position: relative;
  z-index: -1; // Always at background

  .inner {
    position: absolute;
    width: 50vw; // No animation on mobile, just fixed to one half screen
    max-width: 100%;
    overflow: hidden;
  }
}

// Exception to the mobile first principle
@media screen and (max-width: $mobileBreakPoint) {
  // Stick image on the right half of the screen
  .fat-arrow.fat-arrow-tr,
  .fat-arrow.fat-arrow-br {
    .inner {
      // Stick inner to the right half
      right: 0;
    }
  }

  // Stick image on the left half of the screen
  .fat-arrow.fat-arrow-tl,
  .fat-arrow.fat-arrow-bl {
    .inner {
      // Stick img to the right of .inner
      // good workaround to absolute, which need an image height
      display: flex;
      justify-content: end;
    }
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .fat-arrow {
    .inner {
      width: 100vw;
    }
  }

  .fat-arrow-tl {
    img {
      transform: rotate(12deg);
      transform-origin: right -2300px;
    }
    &.triggered img {
      animation: rotateCounterClockwise 1s ease-in-out;
      transform: rotate(0deg);
    }
  }

  .fat-arrow-bl {
    img {
      transform: rotate(-12deg);
      transform-origin: right 2300px;
    }
    &.triggered img {
      animation: rotateClockwise 1s ease-in-out;
      transform: rotate(0deg);
    }
  }

  .fat-arrow-tr {
    .inner {
      // Stick img to the right
      display: flex;
      justify-content: end;
    }
    img {
      transform: rotate(-12deg);
      transform-origin: 25% -2300px;
    }
    &.triggered img {
      animation: rotateClockwise 1s ease-in-out;
      transform: rotate(0deg);
    }
  }
  .fat-arrow-br {
    .inner {
      // Stick img to the right
      display: flex;
      justify-content: end;
    }
    img {
      transform: rotate(12deg);
      transform-origin: 25% 2300px;
    }
    &.triggered img {
      animation: rotateCounterClockwise 1s ease-in-out;
      transform: rotate(0deg);
    }
  }

  @keyframes rotateClockwise {
    from {
      transform: rotate(-12deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes rotateCounterClockwise {
    from {
      transform: rotate(12deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}
