@media screen and (min-width: $desktopBreakPoint) {
  .timeline-diag {
    position: absolute;
    width: 700px;
    top: 100px;
  }

  .timeline-work {
    position: absolute;
    width: 900px;
    top: 875px;
    padding-left: 250px !important;
  }

  .timeline-sav {
    position: absolute;
    width: 700px;
    top: 1650px;
  }

  .timeline-description {
    color: $grey;
    font-size: $small;
    line-height: $text_line_height;
  }
}

@media screen and (max-width: $desktopBreakPoint) {
  .timeline-description {
    color: $purple;
    font-size: $small_mobile;
    line-height: $text_line_height;
  }
}
