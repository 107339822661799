.badge {
  display: inline-block;
  padding: 10px 20px;
  margin: 3px 0;
  border-radius: 20px;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: $fontWeightBook;
  white-space: nowrap;
}

.badge-green {
  color: $purple;
  background: $linearGreen;
}
.badge-dark {
  color: $white;
  background: $purple;
}
