.small_shadow {
  box-shadow: 0px 10px 20px 0px $shadow_small;
}

.small_shadow_top {
  box-shadow: 0px -10px 20px 0px $shadow_small;
}

.large_shadow {
  box-shadow: 0px 30px 60px 0px $shadow_large;
}

.cards_large_shadow {
  box-shadow: 10px 20px 15px -7px $shadow_small;
}
