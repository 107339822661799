/**
 * ! If you modify the size of the breadcrumb (margin, padding or height)
 * ! Please have a look at the _background.scss file, which need some ajustment ...
 */

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  list-style: none;
  background: none;
}

.breadcrumb-item {
  padding: 0;
  padding-right: 15px;
  flex: 0 0 auto;

  &,
  & a {
    color: $grey;
    font-weight: $fontWeightLight;
    font-size: $xsmall;
    text-decoration: none;
  }

  + .breadcrumb-item {
    &::before {
      display: inline-block;
      padding-right: 10px;
      color: $grey;
      content: '/';
    }
  }

  // vertical align home img
  &:first-child,
  &:first-child a {
    line-height: 11px;
  }
}

.breadcrumb-theme-light {
  .breadcrumb-item {
    &,
    & a {
      color: $white;
    }

    + .breadcrumb-item {
      &::before {
        color: $white;
      }
    }
  }
}

/**
 * Desktop Breadcrum behavior :
 *
 * We do not have enough space for the breadcrumb on smallest desktop resolution (< 1300px)
 * So we have to wait for larger resolution before to change for a desktop breadcrumb presentation
 */

// We still want to behave like a mobile breadcrumb, except padding
@media screen and (min-width: $desktopBreakPoint) {
  .breadcrumb {
    padding-left: 80px;
  }
}

// Ok, now yes we can!
@media screen and (min-width: 1300px) {
  #breadcrumb-container {
    position: absolute;
    top: $desktopHeaderHeight + 100px;
    left: 30px;
    bottom: 0;
  }

  .breadcrumb-item {
    + .breadcrumb-item {
      padding-left: 0;
      padding-top: 15px;

      &::before {
        padding-right: 0px;
        padding-bottom: 10px;
      }
    }
  }
  .breadcrumb {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    margin: 0;

    & svg {
      transform: rotate(90deg) translate(0, -1px);
    }
  }
}
