.caret {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: -10px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 10px solid $purple;
}
