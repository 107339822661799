@use 'mixins';

.button {
  @include mixins.button;

  &.circular {
    padding: 0 !important;
    width: 50px;
  }

  &.small {
    height: 40px;

    &.circular {
      padding: 0;
      width: 40px;
    }
  }

  &.medium {
    height: 70px;

    &.circular {
      padding: 0;
      width: 70px;
    }
  }

  &.large {
    height: 90px;

    &.circular {
      padding: 0;
      width: 90px;
    }
  }

  // Deprecated: .default
  &.default,
  &.primary {
    color: $white;
    background: $linearPink;
    // We can't create transparent border with bg linear gradiant
    // So, we will play with paddings to prevent glitch on hover (no border, border)
    padding: 2px 22px;
    border: 0 none;
  }

  // Deprecated: .secondary
  &.secondary,
  &.light {
    color: $purple;
    background: $white;
  }

  &.dark {
    color: $white;
    background: $purple;
  }

  &.bordered {
    border: 1px solid $purpleTransparent;
  }

  &.borderless {
    border-radius: 0px;
  }

  &.disabled {
    pointer-events: none; // Disable click on link
    opacity: $mediumOpacity;
    background: $grey !important;
  }

  &.fake-disabled {
    opacity: $mediumOpacity;
    background: $grey !important;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .button {
    cursor: pointer;
    &:not(.disabled):not(.fake-disabled) {
      &:hover,
      &.button--hover {
        text-decoration: none;
        box-shadow: none;
      }
    }

    &.default,
    &.primary {
      &:not(.disabled):not(.fake-disabled) {
        &:hover,
        &.button--hover {
          color: $white;
          background: $purple;
        }
        &.bordered-hover:hover,
        &.button--hover {
          border: 2px solid $white;
          padding: 0 20px;
        }
      }
    }

    &.secondary,
    &.light {
      transition: background-color 0.15s ease, border-color 0.15s ease;

      &:not(.disabled):not(.fake-disabled) {
        &:hover,
        &.button--hover {
          color: $white;
          background: $purple;

          & svg path {
            fill: $white;
          }

          & img {
            filter: brightness(0) invert(1);
          }
        }
        &.bordered-hover:hover,
        &.button--hover {
          border: 2px solid $white;
        }
      }
    }

    &.dark {
      transition: background-color 0.15s ease, border-color 0.15s ease;

      &:not(.disabled):not(.fake-disabled) {
        &:hover,
        &.button--hover {
          color: $purple;
          background: $white;

          & svg path {
            fill: $purple;
          }

          & img {
            filter: invert(1) brightness(0);
          }
        }
        &.bordered-hover:hover,
        &.button--hover {
          border: 2px solid $purple;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &.dark {
    background-color: $purple;
  }
  &.light {
    background-color: $purpleTransparent;
  }
  &.white {
    background-color: $white;
  }
}

/*
 * To use a css-icon, please use a `.circular` Button
 */
.cssIcon-hamburger {
  .lines {
    // Todo: Manage all button sizes
    width: 20px;
    height: 20px;
    position: relative;
  }

  .line {
    background: $purple;
    transform-origin: center center;
    transition: all 150ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
  }

  &.dark {
    .line {
      background: $white;
    }
  }

  .line1 {
    transform: translate(0, 3px);
  }
  .line2 {
    transform: translate(0, 9px);
  }
  .line3 {
    transform: translate(0, 15px);
  }
  &.toggled .line1 {
    transform: translate(0, 9px) rotate(45deg) scale(0.8);
  }
  &.toggled .line2 {
    opacity: 0;
  }
  &.toggled .line3 {
    transform: translate(0, 9px) rotate(-45deg) scale(0.8);
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .cssIcon-hamburger {
    &.light {
      &:hover {
        .line {
          background: $white;
        }
      }
    }

    &.dark {
      &:hover {
        .line {
          background: $purple;
        }
      }
    }
  }
}
