.divider {
  border-top: 1px solid $lightGrey;
}
.bottom_divider {
  border-bottom: 1px solid $lightGrey;
}
.divider_light {
  border-top: 1px solid #48405c;
}
.form-divider {
  margin: 0 auto;
  border: 1px solid #48405c;
  width: 20%;
}
