.footerCallRequestButtonComp {
  border: 0 none;

  position: fixed;
  bottom: 20px;
  right: 25px;
}

.comp-callRequest {
  .cgu {
    line-height: 1.2;
    font-size: 0.9rem;
    text-align: justify;
  }
}

/*
 * Per page customization
 */

.homepageTitleBloc {
  min-height: 300px;

  @media screen and (min-width: $desktopBreakPoint) {
    min-height: 602px;
  }
}

body.homePage {
  .youtube-iframe {
    padding-bottom: 52.2%;
  }
  .guarantees {
    margin: 50px auto 20px;
    text-align: center;

    & > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }

    @media screen and (min-width: $desktopBreakPoint) {
      text-align: left;
      p {
        margin-bottom: 0;
      }
      & > div {
        justify-content: flex-start;
      }
    }
  }
}

// SIMPLE
body.simplePage {
  // Page simple can contains long word as "MaPrimeRenov'"
  // we want to specificaly reduce the font size on mobile
  @media screen and (max-width: $desktopBreakPoint) {
    h1 {
      font-size: $large_mobile !important;
    }
  }

  .fat-arrow-tl,
  .fat-arrow-tr {
    display: none;
  }
}

// SOLUTION
body.solutionPage {
  // Breacrumb colors custom
  #breadcrumb-container {
    .breadcrumb-item,
    .breadcrumb-item:before {
      color: $white !important;
    }
    .breadcrumb-item svg .cls-1 {
      stroke: $white !important;
    }
  }
}

body.solutionPage,
body.needPage {
  .fixedFloatingCard .card {
    padding: 20px 30px !important;
  }
}

// COMPANY
body.companyPage {
  #ceoPortrait {
    position: relative;

    @media screen and (min-width: $desktopBreakPoint) {
      .inner {
        overflow: hidden;
        position: absolute;
        right: 0;
        height: 650px;
        width: 1011px;
      }

      img {
        position: absolute;
        right: -25%;
        height: 100%;
        width: 100%;
      }
    }
    @media screen and (min-width: $DesktopLargeBreakPoint) {
      img {
        right: -15%;
      }
    }
    @media screen and (min-width: 1500px) {
      img {
        right: 0;
      }
    }
  }
}

// SIMULATORS & leadFormPartner
body.simulatorPage,
body.leadFormPartnerPage {
  .nav-desktop,
  .headerMobile {
    background-color: rgba(255, 255, 255, 0.95);
  }
  @media screen and (min-width: 880px) {
    .nav-desktop.transparent {
      background-color: transparent;
    }
  }

  .fixed-arrow {
    position: fixed;
    bottom: 0;
    z-index: -2;
  }

  .closeSimulator {
    position: fixed;
    top: 18px;
    right: 30px;
    z-index: 200;
  }

  @media screen and (min-width: $desktopBreakPoint) {
    .closeSimulator {
      top: 30px;
      right: 50px;
    }
  }

  .footer {
    font-size: 0.9em;

    a {
      font-weight: $fontWeightLight;
    }
  }

  .legalNotice {
    text-align: center;

    a {
      font-size: 0.9em;
      font-weight: $fontWeightLight;
    }
  }

  @media screen and (max-width: 767px) {
    // this width corresponds to the simulator mobile=>tablet breakpoint
    .legalNotice {
      margin-top: -40px; // On mobile, simulateur add 50px extra space to compensate fixed bottom bar
      margin-bottom: 80px;
    }
  }
  @media screen and (min-width: 768px) {
    .comp-simulateur {
      min-height: 690px;
    }
    .logoSeparator {
      font-size: 0.9rem;
      margin: 8px 10px 0;
    }
  }

  button {
    font-size: 13px;
  }

  .modal {
    a {
      text-decoration: underline;
    }

    h1 {
      font-size: 24pt;
    }
    h2 {
      font-size: 19pt;
    }
    h3 {
      font-size: 16pt;
    }

    ul {
      list-style: circle;
      padding-left: 20px;
    }
  }

  .notSupportedBrowser {
    margin-top: 100px;
    padding: 1rem;
    text-align: center;
  }

  .popup-bpce {
    --padding: 1rem;

    margin: 1rem 1rem 2rem 1rem;

    border-radius: 4px;
    padding: var(--padding);

    border: 1px solid #ccc;
    background-color: white;

    background-repeat: no-repeat;
    background-position: 1rem 1rem;
    background-size: 18%;
    padding-left: calc(2rem + 17%);

    @media screen and (min-width: 480px) {
      background-size: 80px;
      padding-left: calc(2rem + 80px);
    }

    @media screen and (min-width: 768px) {
      width: 550px;
      margin: 1rem auto 2rem;
    }

    .popup-bpce__text {
      color: #333;
      line-height: 1.5;

      & :first-child {
        font-size: 22px;
        font-weight: 600;
        margin: 0.1rem 0;
      }
      & :last-child {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1.2rem;
      }
    }
    .popup-bpce__login {
      a {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        color: white;
        border: 0;
        border-radius: 0.25rem;
        padding: 0.4rem 1.75rem;
        font-weight: 500;
        text-decoration: none;
        width: max-content;

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url('../images/bpce/logout.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:hover {
          transition: background-color 0.2s ease;
        }
      }
    }

    &.popup-bpce--bp {
      background-image: url('../images/bpce/baguette_BP.svg');

      a {
        background-color: hsl(228, 91.5%, 23.1%);
        &:hover {
          background-color: hsl(228, 91.5%, 17%);
        }
      }
    }
    &.popup-bpce--ce {
      background-image: url('../images/bpce/baguette_CE.svg');

      a {
        background-color: hsl(356, 100%, 42%);
        &:hover {
          background-color: hsl(356, 100%, 36%);
        }
      }
    }
    &.popup-bpce--error {
      padding: var(--padding); // cancel padding for image

      .popup-bpce__text {
        color: red;
      }
    }
  }
}

/*
 * leadFormPartner override
 */
body.leadFormPartnerPage {
  .legalNotice {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/*
 * Glossary
 */

.glossary-index a {
  padding: 0 !important; // Override bootstrap
  font-weight: $fontWeightBold;
}

.glossary-words-list, .news-block-list {
  & > div {
    display: flex;
    margin-bottom: 20px;
    line-height: 1.5;

    &::before {
      content: '\002d';
      margin-right: 5px;
      font-weight: $fontWeightBold;
    }
  }

  h3 {
    line-height: 1.5;
    padding: 0;
    font-weight: $fontWeightBold;
  }
}

/*
 * Responsive Youtube iframes
 */
.youtube-iframe {
  position: relative;
  padding-bottom: 55%; /* ~= 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

/*
 * Feedback card, make icon smaller than small!
 */
.feedback.card {
  .icon {
    width: 33px;
    height: 33px;
  }
}

/*
 * Quick & dirty fix the dirty skills agency template
 */
@media screen and (max-width: 500px) {
  .agencySkills {
    .icon {
      width: 45px;

      img.icon-skill {
        width: 100%;
      }
    }
    h3 {
      font-size: 10pt !important;
    }
  }
}
