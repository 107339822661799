.input-group {
  position: relative;
}

.input-group label {
  position: absolute;
  top: 12px;
  left: 5px;
  font-size: 15px;
  color: $grey;
  transition: color 0.2s, top 0.2s;
}

// :placeholder-shown is an experimental pseudo class and is not supported by Edge
// These are just "bonus" rules
.input-group input:not(:placeholder-shown) + label,
.input-group input:focus + label,
.input-group label.topped {
  top: 4px !important;
  font-size: 10px;
}

.input {
  border: 0px;
  border-bottom: 1px solid $grey;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px 5px 5px 5px;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  background-color: transparent !important;
  z-index: 2; // prevent label to catch mouse clic

  &:focus {
    box-shadow: none;
    border-color: $purple;
  }
}

.input-error {
  border: 0px;
  border-bottom: 1px solid $pink;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px 5px 5px 5px;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  background-color: transparent !important;
  z-index: 2; // prevent label to catch mouse clic

  &:focus {
    box-shadow: none;
    border-color: $purple;
  }
}

.select-placeholder-not-focused {
  position: absolute;
  top: 12px;
  left: 5px;
  font-size: 12px;
  color: $grey;
  transition: color 0.2s, top 0.2s;
}

.select-placeholder-focused {
  top: 0px !important;
  font-size: 10px !important;
  left: 7px !important;
}

.custom {
  color: $purple;
  font-size: 15px;
  border: 0px;
  border-bottom: 1px solid $grey;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px;
  background: transparent url('../images/collapse.svg') no-repeat right 0.75rem center;
  height: 40px;
  z-index: 2;
  width: 100%;
  background-size: 15px;

  &:focus {
    border: 0px;
    border-bottom: 1px solid $purple;
  }

  &.select {
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    padding: 10px 5px 0px 7px;

    & option:checked {
      background: linear-gradient(0deg, $grey 5%, $grey 10%);
      width: 90%;
    }
  }
}
.textarea-label {
  position: absolute;
  top: -15px;
  left: 5px;
  font-size: 10px;
  color: $grey;
}

.textarea {
  border: 0px;
  border-bottom: 1px solid $grey;
  border-right: 1px solid $grey;
  padding: 0px 5px 5px 5px;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-input-file {
  & input[type='file'] {
    display: none;
  }
}

.upload-input {
  background: transparent url('../images/file.svg') no-repeat right 0.5rem bottom 0.3rem;
  background-size: 15px;
}

// hack to disable dropdown autofill on Safari
input[autocomplete='disable_autoComplete']::-webkit-contacts-auto-fill-button,
input[autocomplete='disable_autoComplete']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
