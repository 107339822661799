// Generic colors

.b-none {
  background-color: transparent;
}

.b-dark {
  background-color: $purple !important;
}

.b-mediumdark {
  background-color: $mediumDarkPurple !important;
}

.b-darkest {
  background-color: $darkPurple !important;
}

.b-light {
  background-color: $lightPurple;
}

// Specific colors

.b-white {
  background-color: $white;
}

.b-purple {
  background-color: $purple;
}

.b-purpleTransparent {
  background-color: $purpleTransparent;
}

.b-darkPurple {
  background-color: $darkPurple;
}

.b-mediumDarkPurple {
  background-color: $mediumDarkPurple;
}

.b-lightPurple {
  background-color: $lightPurple;
}

.b-lightGrey {
  background-color: $lightGrey;
}

.b-mediumGrey {
  background-color: $mediumGrey;
}

.b-pink {
  background-color: $pink;
}

.b-blue {
  background-color: $blue;
}

.b-yellow {
  background-color: $yellow;
}

.b-green {
  background-color: $green;
}

.b-lime {
  background-color: $lime;
}

.b-shadow_small {
  background-color: $shadow_small;
}

.b-shadow_large {
  background-color: $shadow_large;
}

.b-linearPurple {
  background: $linearPurple;
}

.b-linearPink {
  background: $linearPink;
}

.b-linearGreenBlue {
  background: $linearGreenBlue;
}

.b-linearBlue {
  background: $linearBlue;
}

.b-linearBlue {
  background: $linearBlue;
}

.b-linearOrange {
  background: $linearOrange;
}

.b-linearGreen {
  background: $linearGreen;
}

// Misc

.b-image {
  background-image: url('../images/header_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -($mobileHeaderHeight + 56px); // Take care of header + breadcrumb size ...
  height: 800px;
}

// Take care of header + breadcrumb size ...
@media screen and (min-width: $desktopBreakPoint) {
  .b-image {
    margin-top: -($desktopHeaderHeight + 56px);
  }
}
@media screen and (min-width: 1300px) {
  .b-image {
    margin-top: -($desktopHeaderHeight);
  }
}

.b-image-mobile {
  background-image: url('../images/header_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -12px;
}

.b-pill-orange {
  background-image: url('../images/pill_orange.png');
  background-repeat: no-repeat;
  background-size: initial;
  background-position: center;
}

.b-margin-correction {
  margin-top: 70px;
}

.b-margin-correction-mobile {
  margin-top: 40px;
}
