.circular_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &.medium {
    width: 70px;
    height: 70px;
  }

  &.large {
    width: 90px;
    height: 90px;
  }

  &.dark {
    background-color: $darkPurple;
  }
  &.light {
    background-color: $white;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.purple {
    background-color: $purple;
  }

  &.bordered {
    background-color: $white;
    border: 1px solid $purpleTransparent;
  }
}

.icon {
  width: 50px;
  height: 50px;

  img {
    max-width: 50px;
    max-height: 50px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    width: 40px;
    height: 40px;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }

  &.medium {
    width: 70px;
    height: 70px;

    img {
      max-width: 70px;
      max-height: 70px;
    }
  }
}

/* Used by SVG icons who need to be rotated (like arrows) */
$value: 45;
@while $value < 360 {
  .svg-icon-#{$value} {
    transform: rotate(#{$value}deg);
  }
  $value: $value + 45;
}
