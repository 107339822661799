.card-v2__grid--article {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 30px;
  justify-content: center;
}

.card-v2 {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 10px 20px 15px -7px $shadow_small;
  background-color: $white;

  > img {
    transition: height 200ms ease-out;
    object-fit: cover;
    width: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  .card-v2__content {
    display: flex;
    flex-direction: column;

    position: relative;
    padding: 30px 20px 0;
    line-height: 1.2;

    .card-v2__badge {
      @extend .badge;
      @extend .badge-dark;

      position: absolute;
      top: -20px;
      width: min-content;
    }

    // title
    a {
      margin-bottom: 0.85rem;

      h3 {
        display: -webkit-box;

        overflow: hidden;
        text-overflow: ellipsis;

        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    // description
    p {
      display: -webkit-box;

      overflow: hidden;
      text-overflow: ellipsis;

      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;

      font-size: 0.85rem;
      margin: 0;
    }
  }

  .card-v2__details {
    position: absolute;
    bottom: 1rem;
    transition: all 100ms ease-out;
    opacity: 0;
    transform: translateY(10px);
  }

  &:hover {
    cursor: pointer;

    .card-v2__details {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /*
   * Article cards
   */
  &.card-v2--article {
    width: 300px;
    height: 345px;

    > img {
      height: 170px;
    }

    &:hover {
      > img {
        height: 120px;
      }
    }
  }

  /*
   * Solution cards
   */
  &.card-v2--solution {
    width: 270px;
    height: 250px;

    > img {
      height: 150px;
    }

    .card-v2__content {
      padding: 30px 60px 0 20px;
    }

    .card-v2__button {
      position: absolute;
      top: 131px;
      right: 10px;
      z-index: 2;
    }

    &:hover {
      > img {
        height: 110px;
      }

      .card-v2__button .icon {
        background-color: $purple;

        svg path {
          fill: $white;
        }
      }
    }
  }
}

.card-review-v2 {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 10px 20px 15px -7px $shadow_small;
  background-color: $white;
  padding: 1.5rem;
  height: 290px;

  > img {
    margin-bottom: 2rem;
  }

  .card-review-v2__text {
    flex-grow: 1;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .card-review-v2__footer {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &.card-review-v2--feedback {
    height: 380px;

    .card-review-v2__text {
      p {
        -webkit-line-clamp: 8;
      }
    }
  }
}

.card-agency {
  position: relative; // used by absolute positioned link

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background: $white;

  h2,
  h3 {
    align-self: center;
    flex-grow: 1;
    font-size: 13pt;
    margin: 0; // reset default
  }

  // trick to make the whole card clickable
  a::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  .agency__name {
    font-weight: bold;
    font-size: 15pt;
  }

  .agency__see-more {
    display: none;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .card-agency {
    a:hover {
      text-decoration: none;
    }
    .agency__see-more {
      display: inline-flex;
      flex-shrink: 0;
    }

    &:hover {
      .agency__see-more {
        @extend .button--hover;
      }
    }
  }
}

.card {
  display: flex;
  border-radius: 20px;
  min-width: 300px;
  max-width: 300px;
  border: 0px;

  @media screen and (min-width: $desktopBreakPoint) {
    &:hover {
      text-decoration: none;
    }
  }

  &.default {
    background-color: $white;
  }
  &.dark {
    background-color: $purple;
  }

  &.signup {
    min-width: 350px;
    max-width: 350px;
    min-height: 531px;
  }
}

.supplierCard {
  height: 140px;
  max-width: 140px;
  min-width: 140px;
  & img {
    max-width: 55%;
    object-fit: contain;
  }
}

.windowInfoMap {
  display: block;
  border-radius: 20px;
  border: 0px;
  background-color: $white;

  &:hover {
    text-decoration: none;
  }
}

.infoBox {
  width: 280px !important;
}

@media screen and (max-width: $mobileBreakPoint) {
  .fixedCard {
    justify-content: center;
    display: flex;
    padding-bottom: 40px;
  }
  .fixedFloatingCard {
    justify-content: center;
    display: flex;
    padding-bottom: 40px;
  }
  .fixedFloatingCardMixed {
    justify-content: center;
    display: flex;
    margin-top: -100px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  .fixedCard {
    position: sticky;
    position: -webkit-sticky;
    top: 130px;
    float: right;
  }
  .fixedFloatingCard {
    position: sticky;
    position: -webkit-sticky;
    margin-top: -100px;
    top: 130px;
    float: right;
  }
  .fixedFloatingCardMixed {
    position: sticky;
    position: -webkit-sticky;
    margin-top: -100px;
    top: 130px;
    float: right;
  }
}
