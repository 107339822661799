table,
tbody {
  border-color: $purple;

  & tr:first-child {
    background-color: $purple;
    color: $white;

    & td {
      padding-left: 0.25rem;
    }
  }

  & tr:not(:first-child) {
    & td {
      color: $purple;
      padding-left: 0.25rem;
    }
  }
}
