// overriding bootstrap grid system

@media screen and (min-width: $centralFixedLargeColumn) {
  .container-fluid {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    & > .row {
      padding-left: 70px;
      padding-right: 70px;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container-large {
    width: $centralFixedLargeColumn;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
    & > .row {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container {
    width: $centralFixedColumn;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
    & > .row {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (min-width: $desktopBreakPoint) and (max-width: $DesktopLargeBreakPoint) {
  .container-fluid {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    & > .row {
      padding-left: 70px;
      padding-right: 70px;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container-large {
    width: $centralFixedColumn;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
    & > .row {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container {
    width: $centralFixedColumn;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
    & > .row {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  .container-fluid {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    & > .row {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container-large {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    & > .row {
      padding-left: 25px;
      padding-right: 25px;
      margin-left: 0;
      margin-right: 0;
    }

    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .container {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
    & > .row {
      padding-left: 25px;
      padding-right: 25px;
      margin-left: 0;
      margin-right: 0;
    }
    & [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
