ul {
  list-style-image: url('../images/list_arrow.png');
  padding-left: 70px;
}

li {
  line-height: $text_line_height_small;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
