a {
  color: $purple;
  font-weight: $fontWeightBold;
}

.link-dark {
  color: $purple;
  font-weight: $fontWeightLight;
  text-decoration: none;
  font-size: $small;
}

.link-light {
  color: $white;
  font-weight: $fontWeightLight;
  text-decoration: none;
  font-size: $small;
}

@media (hover: hover) {
  a:hover {
    color: $purple;
    text-decoration: underline;
  }

  .link-dark:hover {
    text-decoration: none;
    color: $purple;
  }
  .link-light:hover {
    color: $white;
    text-decoration: none;
  }
}

// target anchor links
a[id] {
  scroll-margin-top: 130px;
}
